import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { ADD_CARDID, ADD_HERMANO, BAJA_HERMANO, DEATH_HERMANO, EDIT_HERMANO } from "./graphql-mutations";
import { GET_HERMANOS, GET_HERMANO_BY_CARDID, GET_HERMANO_BY_NID } from "./graphql-queries";
import { useNotifications } from "@magicdidac/notifications";

export const useHermanos = () => {
    const { data, error, loading } = useQuery(GET_HERMANOS)
    const addHermano = useAddHermano()

    return { data: (data && data.getHermanos) ? data.getHermanos : [], error, loading, add: addHermano.add }
}

export const useAddHermano = () => {
    const notifications = useNotifications()
    const [addHermano] = useMutation(ADD_HERMANO, {
        refetchQueries: [{ query: GET_HERMANOS }],
        onError: (error) => {
            notifications.error('No se ha podido añadir al hermano.')
            console.error(error)
        },
        onCompleted: () => {
            notifications.success('Se ha añadido al hermano con éxito.')
        }
    })

    const add = async (variables) => {
        await addHermano({ variables })
    }

    return { add }
}

export const useHermanoByNID = (NID) => {
    const notifications = useNotifications()
    const { data, error, loading } = useQuery(GET_HERMANO_BY_NID, { variables: { NID }, fetchPolicy: 'cache-and-network' })
    const mutationOptions = {
        refetchQueries: [{ query: GET_HERMANO_BY_NID, variables: { NID } }, { query: GET_HERMANOS }],
        onError: (error) => {
            notifications.error('No se ha podido modificar al hermano.')
            console.error(error)
        },
        onCompleted: () => {
            notifications.success('Se ha modificado al hermano.')
        }
    }

    const [editHermano] = useMutation(EDIT_HERMANO, mutationOptions)
    const [cardIdHermano] = useMutation(ADD_CARDID, mutationOptions)
    const [bajaHermano] = useMutation(BAJA_HERMANO, mutationOptions)
    const [deathHermano] = useMutation(DEATH_HERMANO, mutationOptions)

    const getAgeOn = (year) => {
        return (parseInt(year) - new Date(data.getHermanoByNID.BirthDate).getFullYear())
    }

    const edit = async (variables) => {
        variables.NID = NID
        await editHermano({ variables })
    }

    const addCardId = async (CardId) => {
        await cardIdHermano({ variables: { NID, CardId } })
    }

    const baja = async (Flag) => {
        await bajaHermano({ variables: { NID, Flag } })
    }

    const death = async () => {
        await deathHermano({ variables: { NID } })
    }

    return {
        error,
        loading,
        data: (data && data.getHermanoByNID) ? data.getHermanoByNID : null,
        age: (data && data.getHermanoByNID) ? getAgeOn(new Date().getFullYear()) : 0,
        getAgeOn,
        edit,
        addCardId,
        baja,
        death,
    }
}

export const useHermanoByCardId = () => {
    const [CardId, setCardId] = useState('')
    const [getHermano, { data, error, loading }] = useLazyQuery(GET_HERMANO_BY_CARDID, { variables: { CardId } })

    const load = async (newCardId) => {
        setCardId(newCardId)
        return await (await getHermano({ variables: { CardId: newCardId } })).data.getHermanoByCardId
    }

    const getAgeOn = (year) => {
        return (parseInt(year) - new Date(data.getHermanoByCardId.BirthDate).getFullYear())
    }

    return {
        data: (data && data.getHermanoByCardId) ? data.getHermanoByCardId : null,
        error,
        loading,
        age: (data && data.getHermanoByCardId) ? getAgeOn(new Date().getFullYear()) : 0,
        getAgeOn,
        load
    }
}