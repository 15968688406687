import { Add, ArrowDropDown, ArrowLeft, ArrowRight, Check, Clear, Close, FilterList, Search, Visibility } from "@mui/icons-material"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, List, ListItem, ListItemButton, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import CreateHermano from "../Components/CreateHermano"
import { CustomCheckbox } from "../Components/CustomCheckbox"
import { useHermanos } from "../Data/Hermanos/custom-hooks"
import { useHermanoTypes } from "../Data/HermanoTypes/custom-hooks"
import { useCuotaTypes } from "../Data/CuotaTypes/custom-hooks"
import { CustomSelect } from "../Components/CustomSelect"
import { compareDates, formatDate, getCurrentYears, getToday, normalizeStr } from "../utils"
import * as XLSX from 'xlsx'

const AllKeys = [
    { key: 'NID', label: 'NID' },
    { key: 'Name', label: 'Nombre' },
    { key: 'LastName', label: 'Apellidos' },
    { key: 'BirthDay', label: 'F. Nacimiento' },
    { key: 'Email', label: 'Email' },
    { key: 'DNI', label: 'DNI' },
    { key: 'Phone', label: 'Teléfono' },
    { key: 'Address', label: 'Dirección' },
    { key: 'Zip', label: 'C.P.' },
    { key: 'City', label: 'Ciudad' },
]

const HermanosPage = () => {
    const navigate = useNavigate()
    const hermanos = useHermanos()
    const hermanosTypes = useHermanoTypes()
    const cuotasTypes = useCuotaTypes()
    const [onlyUpToDate, setOnlyUpToDate] = useState(false)
    const [onlyUpperage, setOnlyUpperage] = useState(false)
    const [hermanoType, setHermanoType] = useState('Todos')
    const [cuotaType, setCuotaType] = useState('Todas')
    const [cuotaYear, setCuotaYear] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchWords, setSearchWords] = useState('')
    const [searchKeys, setSearchKeys] = useState([...AllKeys])
    const [anchorEl, setAnchorEl] = useState(null)
    const [openCreate, setOpenCreate] = useState(false)

    const [openCensus, setOpenCensus] = useState(false)
    const [openHermanosFrom, setOpenHermanosFrom] = useState(false)
    const [openNewHermanos, setOpenNewHermanos] = useState(false)
    const [censusDate, setCensusDate] = useState(getToday())
    const [censusYearBefore, setCensusYearBefore] = useState(false)
    const [newHermanosDate, setNewHermanosDate] = useState(getToday())

    const [filtersAnchor, setFiltersAnchor] = useState(null)
    const [actionsAnchor, setActionsAnchor] = useState(null)

    const columns = [
        { field: 'NID', headerName: 'NID', width: 70, type: 'number', align: 'left' },
        { field: 'DNI', headerName: 'DNI', width: 100 },
        { field: 'Type', headerName: 'Tipo', width: 150 },
        { field: 'Name', headerName: 'Nombre', width: 120 },
        { field: 'LastName', headerName: 'Apellidos', width: 170 },
        {
            field: 'CuotaType', headerName: 'Última Cuota', width: 150, renderCell: ({ row }) => {
                if (row.LastCuota) {
                    return `${row.LastCuota.CuotaType} (${row.LastCuota.CuotaYear})`
                }
            }
        },
        {
            field: 'UpToDate', headerName: 'Al Corriente', width: 100, align: 'center', renderCell: ({ row }) => {
                if (row.UpToDate === 1) {
                    return <Check style={{ color: 'green' }} />
                } else {
                    return <Close style={{ color: 'red' }} />
                }
            }
        },
        {
            field: 'actions', type: 'actions', align: 'right', flex: .5,
            getActions: ({ row }) => {
                return [
                    <Tooltip title='Ver Hermano'>
                        <GridActionsCellItem
                            label='Ver Hermano'
                            icon={<Visibility />}
                            onClick={() => navigate('/hermano/' + row.NID)}
                        />
                    </Tooltip>
                ]
            }
        },
    ]

    const hermanoTypeOptions = [
        'Todos',
        ...hermanosTypes.data.map((t) => t.Name)
    ]

    const cuotaTypeOptions = [
        'Todas',
        ...cuotasTypes.data.map((t) => t.Name)
    ]

    const getHeramnosToShow = () => {
        let hermanosToShow = [...hermanos.data]

        if (onlyUpToDate) {
            hermanosToShow = hermanosToShow.filter((h) => h.UpToDate)
        }

        if (onlyUpperage) {
            hermanosToShow = hermanosToShow.filter((hermano) => getCurrentYears(hermano.BirthDate, new Date()) >= 18)
        }

        if (hermanoType !== 'Todos') {
            hermanosToShow = hermanosToShow.filter((h) => h.Type === hermanoType)
        }

        if (cuotaType !== 'Todas') {
            hermanosToShow = hermanosToShow.filter((h) => h.LastCuota && h.LastCuota.CuotaType === cuotaType)
        }

        if (cuotaYear !== '') {
            hermanosToShow = hermanosToShow.filter((h) => h.Cuotas.find((cuota) => cuota.CuotaYear === cuotaYear))
        }

        const lowerSearch = normalizeStr(searchWords)
        let moreHermanosToShow = []
        if (lowerSearch !== '') {
            if (searchKeys.find((k) => k.key === 'Name') && searchKeys.find((k) => k.key === 'LastName')) {
                moreHermanosToShow = hermanosToShow.filter((h) =>
                    normalizeStr([h.Name, h.LastName].join(' ')).includes(lowerSearch)
                )
            }
            hermanosToShow = hermanosToShow.filter((h) =>
                searchKeys.some(({ key }) => h[key] && normalizeStr(h[key].toString()).includes(lowerSearch))
            )
        }

        for (let h of moreHermanosToShow) {
            if (!hermanosToShow.includes(h)) {
                hermanosToShow.push(h)
            }
        }

        return hermanosToShow
    }

    const checkAllKeys = () => {
        setSearchKeys(searchKeys.length !== AllKeys.length ? [...AllKeys] : [])
    }

    const toggleKeyChecked = (key) => {
        let newSerachKeys = [...searchKeys]

        if (!newSerachKeys.find((k) => k.key === key.key)) {
            newSerachKeys.push(key)
        } else {
            newSerachKeys = newSerachKeys.filter((k) => k.key !== key.key)
        }

        setSearchKeys(newSerachKeys)
    }

    const getHermanosPaidBefore = (paymentDate, underage = true) => {
        let allHermanos = [...hermanos.data]

        if (!underage) {
            allHermanos = allHermanos.filter((hermano) => getCurrentYears(hermano.BirthDate, paymentDate) >= 18)
        }

        allHermanos = allHermanos.filter((hermano) => {
            const cuota = hermano.Cuotas.find((c) => c.CuotaYear === new Date(paymentDate).getFullYear().toString())

            if (!cuota) return false

            return compareDates(cuota.PaymentDate, paymentDate) <= 0
        })

        allHermanos = allHermanos.map((hermano) => {
            const cuota = hermano.Cuotas.find((c) => c.CuotaYear === new Date(paymentDate).getFullYear().toString())

            return {
                ...hermano,
                censusCuota: cuota
            }
        })

        return allHermanos
    }

    const getCensus = () => {
        const census = getHermanosPaidBefore(censusDate, false)

        if (censusYearBefore) {
            const lastYearDate = `${new Date(censusDate).getFullYear() - 1}-12-31`
            const yearBefore = getHermanosPaidBefore(lastYearDate, false)

            for (const hermano of yearBefore) {
                if (!census.find((c) => c.NID === hermano.NID)) {
                    census.push(hermano)
                }
            }
        }
        census.sort((a, b) => a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase()))

        return census.map((hermano, index) => ({
            'N.': index + 1,
            NID: hermano.NID,
            DNI: hermano.DNI,
            Cuota: hermano.censusCuota.CuotaYear,
            Email: hermano.Email,
            Nombre: `${hermano.LastName}, ${hermano.Name}`,
            Carnet: hermano.CardId,
            Firma: ''
        }))
    }

    const getNewHermanos = () => {
        let allHermanos = [...hermanos.data]

        const newHermanosYear = new Date(newHermanosDate).getFullYear()

        allHermanos = allHermanos.filter((hermano) => !hermano.Cuotas.find((cuota) => parseInt(cuota.CuotaYear) < newHermanosYear))

        allHermanos = allHermanos.filter((hermano) => {
            const cuota = hermano.Cuotas.find((c) => c.CuotaYear === newHermanosYear.toString())

            if (!cuota) {
                if (hermano.Cuotas.length > 0) return true
                return false
            }

            return compareDates(newHermanosDate, cuota.PaymentDate) <= 0
        })

        allHermanos = allHermanos.map((hermano) => {
            const cuota = hermano.Cuotas.find((c) => c.CuotaYear === newHermanosYear.toString())

            return {
                ...hermano,
                newHermanoCuota: (!cuota) ? hermano.LastCuota : cuota
            }
        })

        allHermanos.sort((a, b) => a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase()))

        return allHermanos.map((hermano) => ({
            NID: hermano.NID,
            Apellidos: hermano.LastName,
            Nombre: hermano.Name,
            DNI: hermano.DNI,
            Carnet: hermano.CardId,
            "F. Nacimiento": formatDate(hermano.BirthDate),
            "Pago Cuota": formatDate(hermano.newHermanoCuota.PaymentDate),
            "Año Cuota": hermano.newHermanoCuota.CuotaYear
        }))
    }

    const handleExportExcel = (fileName, list) => {
        const worksheet = XLSX.utils.json_to_sheet(list)
        const workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workbook, worksheet, fileName)
        XLSX.writeFile(workbook, fileName + '.xlsx')
    }

    const handleCloseAll = () => {
        setOpenCensus(false)
        setOpenHermanosFrom(false)
        setOpenNewHermanos(false)
        setCensusDate(getToday())
        setNewHermanosDate(getToday())
    }

    return (
        <Box>
            <Stack direction='column' gap='1rem'>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h4'>Hermanos</Typography>
                    <Stack direction='row' gap='.5rem' alignItems='center'>
                        <Button size='small' disabled={hermanos.loading} startIcon={(!!actionsAnchor) ? <ArrowDropDown /> : <ArrowRight />} onClick={(event) => setActionsAnchor(event.currentTarget)}>Acciones</Button>
                        <Button variant='contained' startIcon={<Add />} onClick={() => setOpenCreate(true)}>Añadir</Button>
                    </Stack>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <div>
                        <TextField
                            placeholder="Buscar..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton disabled={!searchWords} style={{ padding: 0 }} onClick={() => setSearchWords('')}>
                                        <Clear fontSize="small" />
                                    </IconButton>
                                )
                            }}
                            variant="standard"
                            type='text'
                            value={searchWords}
                            onChange={(e) => setSearchWords(e.target.value)}
                        />
                        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} >{(anchorEl) ? <ArrowDropDown /> : <ArrowLeft />}</IconButton>
                    </div>
                    <IconButton onClick={(event) => setFiltersAnchor(event.currentTarget)}><FilterList /></IconButton>
                </Stack>
                <DataGrid
                    style={{
                        minHeight: 'calc(100vh - 13rem)',
                        maxHeight: 'calc(100vh - 13rem)',
                        maxWidth: 'calc(100vw - 7rem)'
                    }}
                    disableSelectionOnClick
                    getRowId={(row) => row.NID}
                    loading={hermanos.loading}
                    rows={getHeramnosToShow()}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    pageSizeOptions={[10, 50, 100]}
                    onPageSizeChange={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'NID', sort: 'desc' }]
                        },
                        pagination: { paginationModel: { pageSize: 10 } }
                    }}
                />
            </Stack>

            <CreateHermano open={openCreate} closeHandler={() => setOpenCreate(false)} successHandler={async (params) => await hermanos.add(params)} />
            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack direction='row' alignItems='center' p='0 1rem 0 1rem' style={{ cursor: 'pointer' }} onClick={checkAllKeys}>
                    <Checkbox indeterminate={searchKeys.length > 0 && searchKeys.length < AllKeys.length} checked={searchKeys.length === AllKeys.length} />
                    <Typography variant="body2">Todos</Typography>
                </Stack>
                {AllKeys.map((k) =>
                    <Stack key={k.key} direction='row' alignItems='center' p='0 1rem 0 2rem' style={{ cursor: 'pointer' }} onClick={() => toggleKeyChecked(k)}>
                        <Checkbox size='small' checked={!!searchKeys.find((key) => key.key === k.key)} />
                        <Typography variant="body2">{k.label}</Typography>
                    </Stack>
                )}
            </Popover>
            <Popover
                open={!!filtersAnchor}
                onClose={() => setFiltersAnchor(null)}
                anchorEl={filtersAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack direction='column' gap='1rem' padding='1rem'>
                    <CustomSelect
                        label='Cuota'
                        value={cuotaType}
                        size='small'
                        onChange={(e) => setCuotaType(e.target.value)}
                        options={cuotaTypeOptions}
                    />
                    <CustomSelect
                        label='Hermano'
                        value={hermanoType}
                        size='small'
                        onChange={(e) => setHermanoType(e.target.value)}
                        options={hermanoTypeOptions}
                    />
                    <TextField
                        label='Año'
                        value={cuotaYear}
                        onChange={(e) => setCuotaYear(e.target.value)}
                        variant='standard'
                    />
                    <CustomCheckbox label='Solo al corriente' typographyVariant="body2" checked={onlyUpToDate} onChange={(value) => setOnlyUpToDate(value)} />
                    <CustomCheckbox label='Solo mayores de edad' typographyVariant="body2" checked={onlyUpperage} onChange={(value) => setOnlyUpperage(value)} />
                </Stack>
            </Popover>
            <Popover
                open={!!actionsAnchor}
                onClose={() => setActionsAnchor(null)}
                anchorEl={actionsAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Box sx={{ maxWidth: '360px' }}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setOpenCensus(true)
                                setActionsAnchor(null)
                            }}>
                                Exportar Censo
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                handleExportExcel('ListaDeHermanos', getHeramnosToShow())
                                setActionsAnchor(null)
                            }}>
                                Exportar lista actual
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setOpenNewHermanos(true)
                                setActionsAnchor(null)
                            }}>
                                Exportar nuevos Hermanos
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setOpenHermanosFrom(true)
                                setActionsAnchor(null)
                            }}>
                                Exportar Hermanos desde...
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Popover>
            <Dialog
                open={openCensus}
                onClose={() => handleCloseAll()}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>Exportar Censo Electoral</DialogTitle>
                <DialogContent >
                    <Stack gap='1rem' direction='column' alignItems='end'>
                        <Typography variant="body2">
                            Selecciona la fecha de la convocatoría de elecciones para exportar el censo electoral.
                            Solo entran en el censo los hermanos que tengan más de 18 años y tengan la cuota del año en curso a la fecha de convocatoría de elecciones.
                        </Typography>
                        <TextField
                            label='Fecha'
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { min: new Date().getFullYear() + '-01-01', max: getToday() } }}
                            value={censusDate}
                            onChange={(e) => setCensusDate(e.currentTarget.value)}
                            type='date'
                        />
                        <CustomCheckbox label='Año anterior' checked={censusYearBefore} onChange={(newValue) => setCensusYearBefore(newValue)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={() => handleCloseAll()}>Cancelar</Button>
                    <Button variant='contained' onClick={() => {
                        handleExportExcel('Censo', getCensus())
                        handleCloseAll()
                    }}>Exportar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openHermanosFrom}
                onClose={() => handleCloseAll()}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>Exportar Hermanos desde...</DialogTitle>
                <DialogContent >
                    <Stack gap='1rem' direction='column' alignItems='end'>
                        <Typography variant="body2">
                            Selecciona la fecha des de la que quieres saber quienes se han hecho hermanos.
                            Solo entran en el censo los hermanos que tengan más de 18 años y tengan la cuota del año en curso a partir de la fecha seleccionada.
                        </Typography>
                        <TextField
                            label='Fecha'
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { min: new Date().getFullYear() + '-01-01', max: getToday() } }}
                            value={censusDate}
                            onChange={(e) => setCensusDate(e.currentTarget.value)}
                            type='date'
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={() => handleCloseAll()}>Cancelar</Button>
                    <Button variant='contained' onClick={() => {
                        handleExportExcel('ListadoDeHermanos', getCensus(true))
                        handleCloseAll()
                    }}>Exportar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openNewHermanos}
                onClose={() => handleCloseAll()}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>Exportar nuevos Hermanos</DialogTitle>
                <DialogContent >
                    <Stack gap='1rem' direction='column' alignItems='end'>
                        <Typography variant="body2">
                            Selecciona la fecha des de la que quieres saber quienes se han hecho hermanos.
                        </Typography>
                        <TextField
                            label='Fecha'
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputProps: { max: getToday() } }}
                            value={newHermanosDate}
                            onChange={(e) => setNewHermanosDate(e.currentTarget.value)}
                            type='date'
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={() => handleCloseAll()}>Cancelar</Button>
                    <Button variant='contained' onClick={() => {
                        handleExportExcel('ListadoNuevosHermanos', getNewHermanos())
                        handleCloseAll()
                    }}>Exportar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default HermanosPage