import { gql } from '@apollo/client'

export const ADD_HERMANOTYPE = gql`
mutation AddHermanoType($Name: String!, $Priority: Int!) {
    addHermanoType(Name: $Name, Priority: $Priority) {
        Name
        Priority
    }
}
`

export const REMOVE_HERMANOTYPE = gql`
mutation RemoveHermanoType($Name: String!) {
    removeHermanoType(Name: $Name) {
        Name
        Priority
    }
}
`

export const EDIT_HERMANOTYPE = gql`
mutation EditHermanoType($Name: String!, $Priority: Int!) {
    editHermanoType(Name: $Name, Priority: $Priority) {
        Name
        Priority
    }
}
`

export const CHANGE_ALL_HERMANOTYPES = gql`
mutation ChangeAllHermanoTypes($HermanoTypes: [HermanoTypeInput]!) {
    changeAllHermanoTypes(HermanoTypes: $HermanoTypes) {
        Name
        Priority
    }
}
`