import { Drawer } from "@mui/material"
import hermanosForm from "../Forms/HermanosForm"
import { useHermanoTypes } from "../Data/HermanoTypes/custom-hooks"
import { Form } from "./Form"

const CreateHermano = ({ open, closeHandler, successHandler }) => {

    const hermanoTypes = useHermanoTypes()

    const getOptions = () => {
        return {
            'Type': hermanoTypes.data.map(t => {
                return t.Name
            })
        }
    }

    const handler = async (params) => {
        await successHandler(params)
        closeHandler()
    }

    return (
        <Drawer
            PaperProps={{ sx: { width: { xs: "100%", md: 500 }, padding: '1rem' } }}
            anchor="right"
            open={open}
            onClose={closeHandler}
        >
            <Form
                title='Añadir Hermano'
                buttonLabel='Añadir'
                fields={hermanosForm}
                options={getOptions()}
                cb={handler}
                focusFirst
            />
        </Drawer>
    )
}

export default CreateHermano