import { gql } from '@apollo/client'

export const GET_CUOTATYPES = gql`
query GetCuotaTypes {
    getCuotaTypes {
        Name
        Price
        FromDate
        ToDate
    }
}
`