import { gql } from '@apollo/client'

export const PAY_CUOTA = gql`
mutation AddCuota($NID: String!, $CuotaYear: String!, $CuotaType: String!) {
    addCuota(NID: $NID, CuotaYear: $CuotaYear, CuotaType: $CuotaType) {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`

export const EDIT_CUOTA = gql`
mutation EditCuota($NID: String!, $CuotaYear: String!, $CuotaType: String!) {
    editCuota(NID: $NID, CuotaYear: $CuotaYear, CuotaType: $CuotaType) {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`

export const REMOVE_CUOTA = gql`
mutation RemoveCuota($NID: String!, $CuotaYear: String!) {
    removeCuota(NID: $NID, CuotaYear: $CuotaYear) {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`