export const allHermanoFields =
    `
    NID
    DNI
    Name
    LastName
    BirthDate
    UpToDate
    Type
    Phone
    Email
    City
    Address
    Zip
    CardId
    Death
    Baja
    LastCuota {
      Id
      NID
      Name
      CuotaYear
      PaymentDate
      CuotaType
      Price
    }
    Cuotas {
      Id
      NID
      Name
      CuotaYear
      PaymentDate
      CuotaType
      Price
    }
`