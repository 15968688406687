import { Button, Drawer, MenuItem, Select, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useCuotasByNID } from "../Data/Cuotas/custom-hooks"
import { useCuotaTypes } from "../Data/CuotaTypes/custom-hooks"
import { useHermanoByNID } from "../Data/Hermanos/custom-hooks"
import { getYear, hasCuotaType, possibleYears } from "../utils"
import { AcceptCancelDialog } from "./AcceptCancelDialog"
import { CustomCheckbox } from "./CustomCheckbox"

export const PayCuota = ({ open, onClose, NID }) => {
    const [allYears, setAllYears] = useState([])
    const [isJubilado, setIsJubilado] = useState(false)
    const [CuotaYear, setCuotaYear] = useState()
    const [CuotaType, setCuotaType] = useState()
    const [openWarning, setOpenWarning] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const cuotaTypes = useCuotaTypes()
    const hermano = useHermanoByNID(NID)
    const cuotas = useCuotasByNID(NID)

    useEffect(() => {
        const years = possibleYears(hermano.data, cuotas.data)
        setAllYears(years)
        setCuotaYear(years[0])
        setIsJubilado(hasCuotaType(cuotas.data, cuotaTypes.last) || hermano.age >= cuotaTypes.last.FromDate)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hermano.loading, cuotas.loading, open])

    const handler = () => {
        setSubmitting(true)

        const age = parseInt(CuotaYear) - parseInt(getYear(hermano.data.BirthDate))
        let type = isJubilado ? cuotaTypes.last : cuotaTypes.data.find((t) => (t.ToDate) ? age >= t.FromDate && age <= t.ToDate : age >= t.FromDate)

        if (type.Name === cuotaTypes.last.Name && age <= cuotaTypes.first.ToDate) {
            type = cuotaTypes.first
        }

        setCuotaType(type.Name)

        if (type.Name === cuotaTypes.last.Name && age < cuotaTypes.last.FromDate) {
            setOpenWarning(true)
        }
        else {
            payCuota(CuotaYear, type.Name)
        }

    }

    const payCuota = async (Year, Type) => {
        await cuotas.pay(Year, Type)

        if (hermano.data.Baja && parseInt(Year) === new Date().getFullYear()) {
            await hermano.baja(false)
        }

        onClose()
        setSubmitting(false)
    }

    const handleClose = () => {
        if (submitting) return
        onClose()
    }

    const checkAgeToCheckbox = () => {
        const age = hermano.getAgeOn(CuotaYear)

        return (age > cuotaTypes.first.ToDate && age < cuotaTypes.last.FromDate)
    }

    return (
        <Drawer
            PaperProps={{ sx: { width: { xs: "100%", md: 500 }, padding: '1rem' } }}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <Typography variant="h6">Cobrar Cuota</Typography>
            <Stack direction='column' padding='2rem 0'>
                <Select
                    required
                    label='Año de la Cuota'
                    variant='standard'
                    value={CuotaYear}
                    onChange={(e) => setCuotaYear(e.target.value)}
                    disabled={submitting}
                >
                    {allYears.map((o) => {
                        return (
                            <MenuItem key={o} value={o}>{o}</MenuItem>
                        )
                    })}
                </Select>
                {checkAgeToCheckbox() && <CustomCheckbox label='Es Jubilado' disabled={submitting} checked={isJubilado} onChange={(value) => setIsJubilado(value)} />}
            </Stack>
            <Stack direction='row' gap='1rem' justifyContent='end'>
                <Button variant='outlined' disabled={submitting} onClick={handleClose}>Cancelar</Button>
                <Button variant='contained' disabled={submitting} onClick={handler}>Cobrar Cuota</Button>
            </Stack>
            <AcceptCancelDialog
                title='¿Estás seguro?'
                contentText={`Estas cobrando una cuota como ${cuotaTypes.last.Name} a un hermano que no tiene la edad.\n¿Es correcto?`}
                acceptLabel='Cobrar Cuota'
                open={openWarning}
                onAccept={() => payCuota(CuotaYear, CuotaType)}
                onCancel={() => setSubmitting(false)}
                onClose={() => setOpenWarning(false)}
                blocking
            />
        </Drawer>
    )
}