import { MenuItem, Select, Stack, Typography } from "@mui/material"
import { capitalize } from "../utils"

export const CustomSelect = ({ label, value, onChange, options, variant = 'standard', size = 'normal', disabled = false, typographyVariant = 'body2' }) => {

    return (
        <Stack direction='column' gap='.5rem'>
            <Typography variant={typographyVariant}>{label}</Typography>
            <Select
                value={value}
                onChange={onChange}
                disabled={disabled}
                size={size}
                variant={variant}
            >
                {options.map((o) => <MenuItem key={o} value={o}>{capitalize(o)}</MenuItem>)}
            </Select>
        </Stack>
    )
}