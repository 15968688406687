const loginForm = [
    {
        name: 'username', placeholder: 'usuario', label: 'Usuario',
        variant: 'standard', fullWidth: true, required: true, xs: 12, sm: 12
    },
    {
        name: 'password', type: 'password', placeholder: '*****', label: 'Contraseña',
        variant: 'standard', fullWidth: true, required: true, xs: 12, sm: 12
    }
]

export default loginForm