import { Container, Stack } from "@mui/material"
// import { CuotasSection } from "./CuotasSection"
import { HermanosSection } from "./HermanosSection"


export const Settings = () => {

  return (
    <Container>
      <Stack direction='column' gap='1rem'>
        <HermanosSection />
        {/* <CuotasSection /> */}
      </Stack>
    </Container>
  )
}