import { Stack, Typography } from "@mui/material"

export const CustomIconButton = (props) => {

    return (
        <Stack direction='row' gap='.5rem' onClick={props.onClick} style={{ cursor: 'pointer' }}>
            {props.icon}
            <Typography variant="body1" color={props.color ? props.color : 'inherit'}>{props.label}</Typography>
        </Stack>
    )
}