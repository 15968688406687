import { useMutation } from '@apollo/client'
import { SEND_EMAIL } from './graphql-mutations'
import { useNotifications } from '@magicdidac/notifications'

export const useEmails = () => {
    const notifications = useNotifications()
    const [sendEmail] = useMutation(SEND_EMAIL, {
        onError: (error) => {
            notifications.error('No se ha podido enviar el email.')
            console.error(error)
        },
        onCompleted: () => {
            notifications.success('El email se ha enviado correctamente.')
        }
    })

    const send = async (NID, CuotaYear) => {
        await sendEmail({ variables: { NID, CuotaYear } })
    }

    return {
        send
    }
}