import { Box, Divider, Stack, Typography } from "@mui/material"
import { formatCurrency, formatDate } from "../utils"

export const CuotaPreview = ({ cuota }) => {
    if (!cuota) return

    return (
        <div style={{ width: '700px', margin: 'auto', paddingTop: '2rem' }}>
            <Stack direction='row' alignItems='end' justifyContent='space-between'>
                <Typography variant="h5">CUOTA ANUAL DE HERMANDAD {cuota.CuotaYear}</Typography>
                <img src='https://hdad.magicdidac.com/escudo.png' width='75px' alt="Escudo de la Hermandad" />
            </Stack>
            <Typography variant="body1">Período de validez: enero - diciembre {cuota.CuotaYear}</Typography>
            <Box border='1px solid #000' margin='.5rem 0'>
                <Stack padding='.25rem' borderBottom='1px solid #000' direction='row' alignItems='center' gap='.5rem'>
                    <Typography variant='body1'>Hermano:</Typography>
                    <Typography variant='h6'>{cuota.Name} ({cuota.NID})</Typography>
                </Stack>
                <Stack padding='.25rem' borderBottom='1px solid #000' direction='row' alignItems='center' gap='.5rem'>
                    <Typography variant='body1'>Tipo:</Typography>
                    <Typography variant='h6'>{cuota.CuotaType}</Typography>
                </Stack>
                <Stack padding='.25rem' borderBottom='1px solid #000' direction='row' alignItems='center' gap='.5rem'>
                    <Typography variant='body1'>Importe:</Typography>
                    <Typography variant='h6'>{formatCurrency(cuota.Price)}</Typography>
                </Stack>
                <Stack padding='.25rem' direction='row' alignItems='center' gap='.5rem'>
                    <Typography variant='body1'>Fecha:</Typography>
                    <Typography variant='h6'>{formatDate(cuota.PaymentDate)}</Typography>
                </Stack>
            </Box>
            <Typography variant="body2">Hermandad Ntro. P. Jesús Nazareno y Ntra. Sra. de la Esperanza, C/ Rosellón 12-14 G59993634</Typography>
            <Typography variant="body2">Correo electrónico: hermandadmataro@gmail.com Web: hermandadnazareno.com Teléfono: 937579717</Typography>
            <Typography variant="body2">Con esta cuota se contribuye al mantenimiento de la hermandad y la realización de todas las actividades.</Typography>
            <Divider color="black" style={{ margin: '.25rem 0 .5rem 0' }} />
            <Typography variant="body2">Recibo de pago de la cuota de hermandad del año {cuota.CuotaYear}</Typography>
        </div>
    )
}