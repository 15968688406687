import { useMutation, useQuery } from "@apollo/client"
import { GET_HERMANOTYPES } from "./graphql-queries"
import { ADD_HERMANOTYPE, CHANGE_ALL_HERMANOTYPES, EDIT_HERMANOTYPE, REMOVE_HERMANOTYPE } from "./graphql-mutations"
import { GET_HERMANOS } from '../Hermanos/graphql-queries'

export const useHermanoTypes = () => {
    const { data, error, loading } = useQuery(GET_HERMANOTYPES)
    const mutationOptions = { refetchQueries: [{ query: GET_HERMANOTYPES }] }
    const [addHermanoType] = useMutation(ADD_HERMANOTYPE, mutationOptions)
    const [removeHermanoType] = useMutation(REMOVE_HERMANOTYPE, { refetchQueries: [{ query: GET_HERMANOTYPES }, { query: GET_HERMANOS }] })
    const [editHermanoType] = useMutation(EDIT_HERMANOTYPE, mutationOptions)
    const [changeAllHermanoTypes] = useMutation(CHANGE_ALL_HERMANOTYPES, mutationOptions)

    const add = async (Name, Priority) => {
        await addHermanoType({ variables: { Name, Priority } })
    }

    const remove = async (Name) => {
        await removeHermanoType({ variables: { Name } })
    }

    const edit = async (Name, Priority) => {
        await editHermanoType({ variables: { Name, Priority } })
    }

    const change = async (HermanoTypes) => {
        await changeAllHermanoTypes({ variables: { HermanoTypes } })
    }

    return {
        data: (data && data.getHermanoTypes) ? data.getHermanoTypes : [],
        error,
        loading,
        add,
        remove,
        edit,
        change
    }
}