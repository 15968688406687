import { Stack, Typography } from "@mui/material"
import { formatDate } from "../utils"

export const HermanoPreview = ({ hermano }) => {

    return (
        <div style={{ width: '700px', margin: 'auto', paddingTop: '2rem' }}>
            <Stack direction='row' gap='1rem' alignItems='center'>
                <Typography variant='h6'>{hermano.Name} {hermano.LastName}</Typography>
                <Typography vatiant='body1'>#{hermano.NID}</Typography>
            </Stack>
            <Stack direction='row' gap='2rem'>
                <div>
                    <Typography variant="body1">Carnet: {hermano.CardId}</Typography>
                    <Typography variant="body1">DNI: {hermano.DNI}</Typography>
                    <Typography variant="body1">Fecha de Nacimiento: {formatDate(hermano.BirthDate)}</Typography>
                    <Typography variant="body1">Correo electrónico: {hermano.Email}</Typography>
                </div>
                <div>
                    <Typography variant="body1">Teléfono: {hermano.Phone}</Typography>
                    <Typography variant="body1">Dirección: {hermano.Address}</Typography>
                    <Typography variant="body1">Ciudad: {hermano.City}</Typography>
                    <Typography variant="body1">Código Postal: {hermano.Zip}</Typography>
                </div>
            </Stack>
        </div>
    )
}