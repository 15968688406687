import { gql } from '@apollo/client'
import { allHermanoFields } from './common'

export const GET_HERMANOS = gql`
query GetHermanos {
    getHermanos {
        ${allHermanoFields}
    }
}
`

export const GET_HERMANO_BY_NID = gql`
query GetHermanoByNID ($NID: String!) {
    getHermanoByNID (NID: $NID) {
        ${allHermanoFields}
    }
}
`

export const GET_HERMANO_BY_CARDID = gql`
query GetHermanoByCardId ($CardId: String!) {
    getHermanoByCardId (CardId: $CardId) {
        ${allHermanoFields}
    }
}
`