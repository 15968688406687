import { Button, Dialog, DialogActions, DialogContent, MenuItem, Select, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { usePayCuota, usePrintCuota } from "../Data/Cuotas/custom-hooks"
import { useCuotaTypes } from "../Data/CuotaTypes/custom-hooks"
import { useHermanoByCardId } from "../Data/Hermanos/custom-hooks"
import CenterCircularLoading from "./CenterCircularLoading"
import { CuotaPreview } from "./CuotaPreview"
import { CustomCheckbox } from "./CustomCheckbox"
import { HermanoPreview } from "./HermanoPreview"
import { ScanBarcode } from "./ScanBarcode"
import { useNotifications } from "@magicdidac/notifications"

export const PayCuotaByCardId = ({ open, onClose }) => {
    const hermano = useHermanoByCardId()
    const cuotaTypes = useCuotaTypes()
    const payCuota = usePayCuota()
    const notifications = useNotifications()
    const printCuota = usePrintCuota()

    const navigate = useNavigate()

    const [cardId, setCardId] = useState('')
    const [cuotaYear, setCuotaYear] = useState(new Date().getFullYear())
    const [isJubilado, setIsJubilado] = useState(false)
    const [openScanner, setOpenScanner] = useState(true)
    const [openCuotaPreview, setOpenCuotaPreview] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (cardId !== '') {
            checkHermano(cardId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardId])

    const handlerScanner = async (CardId) => {
        if (!open) return
        setLoading(true)
        setOpenScanner(false)
        await hermano.load(CardId)
        setCardId(CardId)
        // useEffect and checkHermano
    }

    const checkHermano = (CardId) => {
        if (!hermano.data) {
            notifications.error(`Ningún hermano tiene el carnet ${CardId}.`)
            setOpenScanner(true)
            setLoading(false)
            setCardId('')
            return
        }

        // Check if is it INFANTIL or JUBILADO
        if (hermano.age <= cuotaTypes.first.ToDate || hermano.age >= cuotaTypes.last.FromDate || !isJubilado) {
            pay(cuotaTypes.getTypeByAge(hermano.age).Name)
        } else {
            pay(cuotaTypes.last.Name)
        }
    }

    const pay = async (CuotaType) => {
        await payCuota.pay(hermano.data.NID, cuotaYear, CuotaType)
        // Tener en cuenta si lo tiene pagado
        setOpenCuotaPreview(true)
        setLoading(false)
    }

    const handleClose = () => {
        if (loading) return

        cleanAndClose()
    }

    const cleanAndClose = () => {
        onClose()
        setCuotaYear(new Date().getFullYear())
        setIsJubilado(false)
        setLoading(false)
        setOpenScanner(true)
        setOpenCuotaPreview(false)
        setCardId('')
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <DialogContent>
                <Stack height='600px'>
                    {loading && <CenterCircularLoading />}
                    {(!loading && openScanner) &&
                        <Stack direction='row' alignItems='start' gap='1rem'>
                            <Select
                                required
                                variant='standard'
                                value={cuotaYear}
                                onChange={(e) => setCuotaYear(e.target.value)}
                                defaultValue={new Date().getFullYear()}
                            >
                                <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                                <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                            </Select>
                            <CustomCheckbox label='Es Jubilado' onChange={(value) => setIsJubilado(value)} checked={isJubilado} typographyVariant='body2' />
                        </Stack>
                    }
                    {(!loading && openScanner) && <ScanBarcode onComplete={handlerScanner} />}
                    {(!loading && openCuotaPreview) &&
                        <Stack>
                            <HermanoPreview hermano={hermano.data} />
                            <CuotaPreview cuota={payCuota.data} />
                        </Stack>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                {(!loading && openCuotaPreview) && <Button disabled={loading} onClick={() => navigate(`/hermano/${hermano.data.NID}`)}>Ver Hermano</Button>}
                {(!loading && openCuotaPreview) && <Button disabled={loading} variant='contained' onClick={() => printCuota.print(payCuota.data)}>Imprimir Cuota</Button>}
                <Button disabled={loading} variant='outlined' onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}