import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Loader } from "./Loader";

function App() {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.REACT_APP_STAGE !== 'prod',
  })

  return (
    <ApolloProvider client={client}>
      <Loader />
    </ApolloProvider>
  )
}

export default App;