import { Button, Stack, TextField, Typography } from "@mui/material"
import { useScanBarcode } from "../Barcode/custom-hooks"
import { useState } from "react"

export const ScanBarcode = ({ onComplete, height = '500px' }) => {
    const [card, setCard] = useState('')

    const handler = async (barcode) => {
        await onComplete(barcode)
        setCard('')
    }

    useScanBarcode({
        onComplete: handler
    })

    return (
        <Stack direction='column' justifyContent='space-between'>
            <Stack alignItems='center' justifyContent='center' height={height}>
                <Typography>Esperando lectura...</Typography>
                {
                    process.env.REACT_APP_STAGE !== 'prod' &&
                    <div>
                        <Button onClick={() => onComplete('0008')}>Fake-Good</Button>
                        <Button onClick={() => onComplete('0009')}>Fake-Bad</Button>
                    </div>
                }
            </Stack>
            <Stack direction='row' gap='.5rem' justifyContent='end'>
                <TextField
                    size='small'
                    label='Carnet'
                    value={card}
                    onChange={(event) => setCard(event.target.value)}
                />
                <Button size='small' variant='contained' onClick={() => handler(card)}>Aceptar</Button>
            </Stack>
        </Stack>
    )
}