import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import CenterCircularLoading from "./CenterCircularLoading"
import { ScanBarcode } from "./ScanBarcode"
import { useHermanoByCardId } from "../Data/Hermanos/custom-hooks"
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material"
import { useNotifications } from "@magicdidac/notifications"

export const CheckHermano = ({ open, onClose }) => {

    const hermano = useHermanoByCardId()
    const notifications = useNotifications()

    const [loading, setLoading] = useState(false)
    const [cardId, setCardId] = useState('')

    useEffect(() => {
        if (cardId !== '')
            checkHermano(cardId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardId])

    const handleScanner = async (cardId) => {
        setLoading(true)
        await hermano.load(cardId)
        setCardId(cardId)
        console.log(hermano.data)
    }

    const checkHermano = (cardId) => {
        if (!hermano.data) {
            notifications.error(`Ningún hermano tiene el carnet ${cardId}.`)
            setCardId('')
        }
        setLoading(false)
    }

    const handleClose = () => {
        if (loading) return
        clearAndClose()
    }

    const clearAndClose = () => {
        onClose()
        setCardId('')
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <DialogContent>
                <Stack style={{ overflow: 'hidden', height: '600px' }} justifyContent='end'>
                    {
                        loading ?
                            <CenterCircularLoading /> :
                            <Stack direction='column'>
                                {hermano.data && cardId !== '' &&
                                    <Stack direction='column' gap='1rem' justifyContent='center' alignItems='center'>
                                        {
                                            hermano.data.UpToDate ?
                                                <CheckCircleOutline fontSize='large' style={{ color: 'green' }} /> :
                                                <CancelOutlined fontSize='large' style={{ color: 'red' }} />
                                        }
                                        <Typography variant="h4" color={(hermano.data.UpToDate) ? 'green' : 'red'}>{hermano.data.Name} {hermano.data.LastName}</Typography>
                                    </Stack>
                                }
                                <ScanBarcode onComplete={handleScanner} height={(hermano.data && cardId !== '') ? '400px' : '500px'} />
                            </Stack>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant='outlined' onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}