import { AssignmentTurnedIn, Flip, Person, Search } from "@mui/icons-material";
import { Box, Stack, Typography, Divider, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayCuotaByCardId } from "../Components/PayCuotaByCardId";
import { ShortcutButton } from "../Components/ShortcutButton";
import { CheckHermano } from "../Components/CheckHermano";
import { SearchHermano } from "../Components/SearchHermano";

const HomePage = () => {
    const navigate = useNavigate()
    const [openPayCuota, setOpenPayCuota] = useState(false)
    const [openCheckHermano, setOpenCheckHermano] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Stack direction='column' gap='2rem'>
                        <div>
                            <Typography variant='h4' color='gray'>Atajos</Typography>
                            <Divider />
                        </div>
                        <Grid container spacing={2} alignItems='stretch'>
                            <Grid item>
                                <ShortcutButton
                                    onClick={() => navigate('/hermanos')}
                                    label='Hermanos'
                                    icon={<Person />}
                                />
                            </Grid>
                            <Grid item>
                                <ShortcutButton
                                    onClick={() => setOpenPayCuota(true)}
                                    label='Cobrar Cuota'
                                    icon={<Flip />}
                                />
                            </Grid>
                            <Grid item>
                                <ShortcutButton
                                    onClick={() => setOpenCheckHermano(true)}
                                    label='Validar Hermano'
                                    icon={<AssignmentTurnedIn />}
                                />
                            </Grid>
                            <Grid item>
                                <ShortcutButton
                                    onClick={() => setOpenSearch(true)}
                                    label='Buscar Hermano'
                                    icon={<Search />}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' gap='2rem'>
                        <div>
                            <Typography variant='h4' color='gray'>Estadisticas</Typography>
                            <Divider />
                        </div>
                        <Typography variant="body1">Estamos trabajando en ello...</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <PayCuotaByCardId open={openPayCuota} onClose={() => setOpenPayCuota(false)} />
            <CheckHermano open={openCheckHermano} onClose={() => setOpenCheckHermano(false)} />
            <SearchHermano open={openSearch} onClose={() => setOpenSearch(false)} />
        </Box>
    )
}

export default HomePage