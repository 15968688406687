import { createSlice } from '@reduxjs/toolkit'
import { defaultPassword, defaultUsername } from '../constants'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false
    },
    reducers: {
        signin: (state, { payload }) => {
            const { username, password } = payload
            if (username === defaultUsername && password === defaultPassword) {
                state.isAuthenticated = true
            }
        },
        signout: (state) => {
            state.isAuthenticated = false
        },
        manualSignin: (state) => {
            state.isAuthenticated = true
        }
    }
})

export const { signin, signout, manualSignin } = authSlice.actions

export default authSlice.reducer