import { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { CuotaPreview } from "../Components/CuotaPreview"

export const PrintCuotaProvider = () => {
    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })
    const [cuota, setCuota] = useState(null)

    useEffect(() => {
        if (cuota) {
            handlePrint()
            setCuota(null)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuota])

    useEffect(() => {
        const print = (event) => {
            setCuota(event.detail)
        }

        window.addEventListener('printCuota', print)

        return () => {
            window.removeEventListener('printCuota', print)
        }
    })

    return (
        <div style={{ display: 'none' }}>
            <div ref={printRef}>
                {cuota &&
                    <div>
                        <CuotaPreview cuota={cuota} />
                        <CuotaPreview cuota={cuota} />
                    </div>
                }
            </div>
        </div>
    )
}