import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useState } from "react"

export const AcceptCancelDialog = ({ open, onClose, title, contentText, onCancel = () => { }, onAccept, cancelLabel = 'Cancelar', acceptLabel = 'Aceptar', blocking = false }) => {
    const [submitting, setSubmitting] = useState(false)

    const handler = async () => {
        setSubmitting(true)
        await onAccept()
        onClose()
        setSubmitting(false)
    }

    const handleCancel = async () => {
        await onCancel()
        onClose()
    }

    const handleClose = () => {
        if (!blocking && !submitting) {
            onClose()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' disabled={submitting} onClick={handleCancel}>{cancelLabel}</Button>
                <Button variant='contained' disabled={submitting} onClick={handler}>{acceptLabel}</Button>
            </DialogActions>
        </Dialog>
    )
}