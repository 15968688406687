import { useQuery } from "@apollo/client"
import { GET_CUOTATYPES } from "./graphql-queries"

export const useCuotaTypes = () => {
    const { data, error, loading } = useQuery(GET_CUOTATYPES)

    const getTypeByAge = (age) => {
        return data.getCuotaTypes.find((t) => (t.ToDate) ? age >= t.FromDate && age <= t.ToDate : age >= t.FromDate)
    }

    return {
        data: (data && data.getCuotaTypes) ? data.getCuotaTypes : [],
        last: (data && data.getCuotaTypes) ? data.getCuotaTypes.find((c) => !c.ToDate) : null,
        first: (data && data.getCuotaTypes) ? data.getCuotaTypes.reduce((prev, curr) => {
            if (!prev.ToDate) return curr
            if (!curr.ToDate) return prev
            return prev.ToDate < curr.ToDate ? prev : curr
        }) : null,
        error,
        loading,
        getTypeByAge
    }
}