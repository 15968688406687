import { useEffect, useState } from "react";

export const useScanBarcode = ({ onComplete, minLength = 3 }) => {
    const [barcode, setBarcode] = useState('')

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 13 && barcode.length > minLength) {
                onComplete(barcode)
                return
            }

            setBarcode(barcode + e.key)

            setTimeout(() => {
                setBarcode('')
            }, 100)
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })
}