import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { GET_HERMANOS, GET_HERMANO_BY_NID } from "../Hermanos/graphql-queries";
import { EDIT_CUOTA, PAY_CUOTA, REMOVE_CUOTA } from "./graphql-mutations";
import { GET_CUOTAS, GET_CUOTAS_BY_NID, GET_CUOTA_BY_NID_AND_CUOTAYEAR } from "./graphql-queries";
import { useNotifications } from "@magicdidac/notifications";

export const useCuotas = () => {
    const { data, error, loading } = useQuery(GET_CUOTAS)

    return { data: (data && data.getCuotas) ? data.getCuotas : [], error, loading }
}

export const useCuotasByNID = (NID) => {
    const refetchQueries = [{ query: GET_CUOTAS_BY_NID, variables: { NID: NID } }, { query: GET_HERMANO_BY_NID, variables: { NID: NID } }, { query: GET_HERMANOS }, { query: GET_CUOTAS }]
    const getMutationObject = (errorLabel, completedLabel) => {
        return {
            refetchQueries,
            onError: (error) => {
                notifications.error(`Ha habido un problema al ${errorLabel} la cuota...`)
                console.error(error)
            },
            onCompleted: () => {
                notifications.success(`¡La cuota ha sido ${completedLabel} con éxito!`)
            }
        }
    }
    const notifications = useNotifications()
    const { data, error, loading } = useQuery(GET_CUOTAS_BY_NID, { variables: { NID: NID } })

    const [addCuota] = useMutation(PAY_CUOTA, getMutationObject('pagar', 'pagada'))
    const [editCuota] = useMutation(EDIT_CUOTA, getMutationObject('modificar', 'modificada'))
    const [removeCuota] = useMutation(REMOVE_CUOTA, getMutationObject('eliminar', 'eliminada'))

    const pay = async (CuotaYear, CuotaType) => {
        await addCuota({ variables: { NID: NID, CuotaYear: CuotaYear.toString(), CuotaType } })
    }

    const edit = async (CuotaYear, CuotaType) => {
        await editCuota({ variables: { NID: NID, CuotaYear, CuotaType } })
    }

    const remove = async (CuotaYear) => {
        await removeCuota({ variables: { NID: NID, CuotaYear: CuotaYear } })
    }

    return { data: (data && data.getCuotasByNID) ? [...data.getCuotasByNID].sort((a, b) => (parseInt(a.CuotaYear) > parseInt(b.CuotaYear) ? -1 : 1)) : [], error, loading, pay, edit, remove }
}

export const usePayCuota = () => {
    const notifications = useNotifications()
    const [hermanoNID, setHermanoNID] = useState('')
    const [addCuota, { data }] = useMutation(PAY_CUOTA, {
        refetchQueries: [
            { query: GET_CUOTAS_BY_NID, variables: { NID: hermanoNID } },
            { query: GET_HERMANO_BY_NID, variables: { NID: hermanoNID } },
            { query: GET_HERMANOS }, { query: GET_CUOTAS }
        ],
        onError: (error) => {
            notifications.error('Ha habido un problema al pagar la cuota...')
            console.error(error)
        },
        onCompleted: () => {
            notifications.success('¡La cuota ha sido pagada con éxito!')
        }
    })

    const pay = async (NID, CuotaYear, CuotaType) => {
        setHermanoNID(NID)
        await addCuota({ variables: { NID, CuotaYear: CuotaYear.toString(), CuotaType } })
    }

    return {
        data: (data && data.addCuota) ? data.addCuota : null,
        pay
    }
}

export const useCuotaByNIDAndCuotaYear = (NID, CuotaYear) => {
    const { data, error, loading } = useQuery(GET_CUOTA_BY_NID_AND_CUOTAYEAR, { variables: { NID, CuotaYear } })

    return {
        data: (data) ? data.getCuotaByNIDAndCuotaYear ?? null : null,
        error,
        loading
    }
}

export const usePrintCuota = () => {
    const print = (cuota) => {
        window.dispatchEvent(new CustomEvent('printCuota', { detail: cuota }))
    }

    return {
        print
    }
}