import { gql } from '@apollo/client'

export const GET_CUOTAS = gql`
query GetCuotas {
    getCuotas {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`

export const GET_CUOTAS_BY_NID = gql`
query GetCuotasByNID ($NID: String!){
    getCuotasByNID (NID: $NID) {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`

export const GET_CUOTA_BY_NID_AND_CUOTAYEAR = gql`
query GetCuotaByNIDAndCuotaYear ($NID: String!, $CuotaYear: String!){
    getCuotaByNIDAndCuotaYear (NID: $NID, CuotaYear: $CuotaYear) {
        Id
        NID
        Name
        CuotaYear
        PaymentDate
        CuotaType
        Price
    }
}
`