import { gql } from '@apollo/client'
import { allHermanoFields } from './common'

export const ADD_HERMANO = gql`
mutation AddHermano($DNI: String, $Name: String!, $LastName: String!, $BirthDate: String!, $Phone: String! $Email: String, $City: String, $Address: String, $Zip: String, $Type: String!) {
    addHermano(DNI: $DNI, Name: $Name, LastName: $LastName, BirthDate: $BirthDate, Phone: $Phone Email: $Email, City: $City, Address: $Address, Zip: $Zip, Type: $Type) {
      ${allHermanoFields}
    }
  }
`

export const ADD_CARDID = gql`
mutation AddCardToHermano($NID: String!, $CardId: String!) {
  addCardToHermano(NID: $NID, CardId: $CardId) {
      ${allHermanoFields}
    }
  }
`

export const EDIT_HERMANO = gql`
mutation EditHermano($NID: String!, $DNI: String, $Name: String!, $LastName: String!, $BirthDate: String!, $Phone: String, $Email: String, $City: String, $Address: String, $Zip: String, $Type: String!) {
  editHermano(NID: $NID, DNI: $DNI, Name: $Name, LastName: $LastName, BirthDate: $BirthDate, Phone: $Phone, Email: $Email, City: $City, Address: $Address, Zip: $Zip, Type: $Type) {
    ${allHermanoFields}
  }
}
`

export const BAJA_HERMANO = gql`
mutation BajaHermano($NID: String!, $Flag: Boolean!) {
  bajaHermano(NID: $NID, Flag: $Flag) {
    ${allHermanoFields}
  }
}
`

export const DEATH_HERMANO = gql`
mutation DeathHermano($NID: String!) {
  deathHermano(NID: $NID) {
    ${allHermanoFields}
  }
}
`