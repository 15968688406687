import { Stack, Tooltip, Typography } from "@mui/material"

export const ShortcutButton = ({ onClick, icon, label }) => {
    const parseLabel = () => {
        const words = label.split(' ')

        if (words.length === 1) {
            return label
        }

        return words[0] + ' ' + words[1][0] + '.'
    }

    return (
        <Tooltip title={label}>
            <div style={{ width: '120px', background: '#e0e0e0', borderRadius: '.5rem', padding: '1rem .5rem', overflow: 'hidden', cursor: 'pointer' }} onClick={onClick}>
                <Stack direction='column' alignItems='center'>
                    {icon}
                    <Typography variant="body1" align='center' noWrap>{parseLabel()}</Typography>
                </Stack>
            </div>
        </Tooltip>
    )
}