import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import CenterCircularLoading from "./CenterCircularLoading"
import { ScanBarcode } from "./ScanBarcode"
import { useHermanoByCardId } from "../Data/Hermanos/custom-hooks"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useNotifications } from "@magicdidac/notifications"

export const SearchHermano = ({ open, onClose }) => {
  const navigate = useNavigate()
  const notifications = useNotifications()

  const [loading, setLoading] = useState(false)
  const byCardId = useHermanoByCardId()

  const handleScanner = async (cardId) => {
    setLoading(true)
    const hermano = await byCardId.load(cardId)

    if (hermano) {
      navigate(`/hermano/${hermano.NID}`)
    } else {
      notifications.error(`Ningun hermano tiene el carntet ${cardId}.`)
    }

    setLoading(false)
  }

  const handleClose = () => {
    if (loading) return
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
    >
      <DialogContent>
        <Stack height='600px' justifyContent='end'>
          {loading && <CenterCircularLoading />}
          {!loading && <ScanBarcode onComplete={handleScanner} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant='outlined' onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}