import { Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../Components/Form";
import { IS_LOGGED, TRUE } from "../constants";
import loginForm from "../Forms/loginForm";
import { signin } from "../Redux/authSlice";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Loading } from "../Components/Loading";

export function Login() {

    const dispatch = useDispatch()
    const authReducer = useSelector(state => state.auth)
    const [cookies, setCookies] = useCookies()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (authReducer.isAuthenticated || cookies[IS_LOGGED] === TRUE) {
            setCookies(IS_LOGGED, TRUE)
            navigate('/home')
        }

        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authReducer, cookies])

    const handleLogin = (values) => {
        dispatch(signin({ username: values.username, password: values.password }))
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginTop: '5rem' }}>
                <Typography variant="h3" align="center">Gestión Hermandad</Typography>
                <Typography variant="h6" align="center">by magicdidac</Typography>
                <Form fields={loginForm} cb={handleLogin} buttonLabel='Iniciar Sesión' />
            </div>
        </div>
    )
}