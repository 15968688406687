import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import { useState } from "react"
import { useHermanoByNID } from "../Data/Hermanos/custom-hooks"
import CenterCircularLoading from "./CenterCircularLoading"
import { ScanBarcode } from "./ScanBarcode"

export const AddCardIdDialog = (props) => {
    const { open, onClose, NID } = props

    const handler = async (CardId) => {
        if (!open) return
        setLoading(true)
        await hermano.addCardId(CardId)
        onClose()
        setLoading(false)
    }

    const [loading, setLoading] = useState(false)
    const hermano = useHermanoByNID(NID)

    const handleClose = () => {
        if (!loading) {
            onClose()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <DialogContent>
                <Stack height='600px' justifyContent='end'>
                    {loading && <CenterCircularLoading />}
                    {!loading &&
                        <ScanBarcode onComplete={handler} />
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant='outlined' onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}