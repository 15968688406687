
const hermanosForm = [
    {
        name: 'Name', placeholder: 'Nombre', label: 'Nombre',
        variant: 'standard', fullWidth: true, required: true, xs: 6, sm: 6
    },
    {
        name: 'LastName', placeholder: 'Apellidos', label: 'Apellidos',
        variant: 'standard', fullWidth: true, required: true, xs: 6, sm: 6
    },
    {
        name: 'BirthDate', type: 'date', label: 'F. Nacimiento', InputLabelProps: { shrink: true },
        variant: 'standard', fullWidth: true, required: true, xs: 4, sm: 4
    },
    {
        name: 'Type', type: 'select', label: 'Tipo de hermano',
        variant: 'standard', fullWidth: true, required: true, xs: 4, sm: 4
    },
    {
        name: 'DNI', placeholder: '00000000X', label: 'DNI',
        variant: 'standard', fullWidth: true, required: false, xs: 4, sm: 4
    },
    {
        name: 'Email', type: 'email', placeholder: 'test@test.com', label: 'Email',
        variant: 'standard', fullWidth: true, required: false, xs: 4, sm: 4
    },
    {
        name: 'Phone', placeholder: '000000000', label: 'Teléfono',
        variant: 'standard', fullWidth: true, required: false, xs: 4, sm: 4
    },
    {
        name: 'Address', placeholder: '', label: 'Dirección',
        variant: 'standard', fullWidth: true, required: false, xs: 4, sm: 4
    },
    {
        name: 'City', placeholder: '', label: 'Ciudad',
        variant: 'standard', fullWidth: true, required: false, xs: 3, sm: 3
    },
    {
        name: 'Zip', placeholder: '00000', label: 'Código postal',
        variant: 'standard', fullWidth: true, required: false, xs: 3, sm: 3
    }
]
export default hermanosForm