import { useMutation } from "@apollo/client"
import { UPDATE_VERSION } from "./graphql-mutations"
import { useEffect, useState } from "react"

export const useUpdater = () => {
  const [lastVersion, setLastVersion] = useState('0.0.0')
  const [loading, setLoading] = useState(true)
  const [isLatest, setIsLatest] = useState(true)

  const [updateVersion] = useMutation(UPDATE_VERSION)

  const loadInfo = async (loadingAgain = true) => {
    if (loadingAgain) {
      setLoading(true)
    }

    const uVersion = await sendVersion()

    setIsLatest(uVersion === process.env.REACT_APP_VERSION)
    setLastVersion(uVersion)
    setLoading(false)
  }

  useEffect(() => {
    loadInfo()

    const interval = setInterval(() => {
      loadInfo(false)
    }, 60 * 60 * 1000)

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendVersion = async () => {
    const data = await updateVersion({ variables: { version: process.env.REACT_APP_VERSION } })
    return data.data.updateVersion
  }

  return {
    loading,
    isLatest,
    lastVersion,
    reload: loadInfo
  }
}