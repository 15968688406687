import { Checkbox, Stack, Typography } from "@mui/material"

export const CustomCheckbox = ({ label, checked, onChange, disabled = false, typographyVariant = 'body1' }) => {

    const handleChange = () => {
        if (disabled) return
        onChange(!checked)
    }

    return (
        <Stack direction='row' alignItems='center' style={{ cursor: 'pointer' }} onClick={handleChange}>
            <Checkbox checked={checked} disabled={disabled} />
            <Typography variant={typographyVariant}>{label}</Typography>
        </Stack>
    )
}