import { Drawer } from "@mui/material"
import editHermanosForm from "../Forms/EditHermanosForm"
import { useHermanoTypes } from "../Data/HermanoTypes/custom-hooks"
import { Form } from "./Form"

const EditHermano = ({ open, closeHandler, successHandler, hermano }) => {
    const hermanoTypes = useHermanoTypes()

    const handler = async (params) => {
        await successHandler(params)
        closeHandler()
    }

    const getOptions = () => {
        return {
            'Type': hermanoTypes.data.map(t => {
                return t.Name
            }),
            defaults: {
                'Type': hermano.Type
            }
        }
    }

    return (
        <Drawer
            PaperProps={{ sx: { width: { xs: "100%", md: 500 }, padding: '1rem' } }}
            anchor="right"
            open={open}
            onClose={closeHandler}
        >
            <Form
                title='Editar Hermano'
                buttonLabel='Aceptar'
                cancelLabel='Cancelar'
                fields={editHermanosForm.map((h) => ({ ...h, defaultValue: hermano[h.name] }))}
                options={getOptions()}
                cb={handler}
                focusFirst
                closeHandler={closeHandler}
            />
        </Drawer>
    )
}

export default EditHermano