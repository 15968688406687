import { Stack, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { primaryColor } from "../theme"

export const SideIcon = ({ icon, url, label }) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Stack
      direction='column'
      style={{
        cursor: 'pointer',
        color: (url === location.pathname) ? primaryColor : 'black'
      }}
      onClick={() => navigate(url)}
      alignItems='center'
    >
      {icon}
      <Typography variant='body2' fontSize='.75rem' >{label}</Typography>
    </Stack>
  )
}