import { CircularProgress } from "@mui/material"

const CenterCircularLoading = () => {
    return (
        <div style={{ width: '100%', height: '100%', padding: '2rem 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </div>
    )
}

export default CenterCircularLoading