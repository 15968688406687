import { useCookies } from "react-cookie"
import { IS_LOGGED, TRUE } from "../constants"
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { manualSignin } from "../Redux/authSlice";

export function PrivateRoutes() {

    const dispatch = useDispatch()
    const authReducer = useSelector(state => state.auth)
    const [cookies] = useCookies([IS_LOGGED])

    if (authReducer.isAuthenticated) return <Outlet />
    if (cookies[IS_LOGGED] === TRUE) {
        dispatch(manualSignin())
        return <Outlet />
    }

    return <Navigate to={''} />
}