import { AppBar, IconButton, Stack, styled, Toolbar, Tooltip, Typography } from "@mui/material"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FALSE, IS_LOGGED } from "../constants"
import { signout } from "../Redux/authSlice"
import { Logout } from '@mui/icons-material'

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between'
})

const Navbar = () => {
    const authReducer = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [, setCookies] = useCookies()

    const signOut = () => {
        setCookies(IS_LOGGED, FALSE)
        dispatch(signout())
        navigate('')
    }

    if (!authReducer.isAuthenticated) {
        return
    }

    return (
        <AppBar position="sticky">
            <StyledToolbar>
                <Stack direction='row' gap='.5rem' alignItems='end'>
                    <Typography variant="h6" sx={{ display: { xs: 'none', md: 'block' } }}>Gestión Hermandad</Typography>
                    <Typography variant="h6" sx={{ display: { xs: 'flex', md: 'none' } }}>GH</Typography>
                    <Typography variant='body2'>v{process.env.REACT_APP_VERSION}{(process.env.REACT_APP_STAGE !== 'prod') && `-${process.env.REACT_APP_STAGE}`}</Typography>
                </Stack>
                <img src='https://hdad.magicdidac.com/escudo.png' width='45px' alt="Escudo de la Hermandad" style={{ position: 'absolute', left: 'calc(50vw - 25px)' }} />
                <Tooltip title='Cerrar Sesión'><IconButton color='info' onClick={signOut}><Logout /></IconButton></Tooltip>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar