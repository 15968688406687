import { Close, Edit, Print, Send } from "@mui/icons-material"
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { formatCurrency, formatDate } from "../utils"

export const CuotaLine = ({ cuota, email, onPrint, onSendEmail, onEdit, onRemove }) => {

    return (
        <Box>
            <Stack style={{ padding: '.25rem 1rem' }} direction='row' alignItems='center' justifyContent='space-between'>
                <Stack direction='row' gap='1rem' alignItems='center'>
                    <Typography variant="h6">{cuota.CuotaYear}</Typography>
                    <Typography variant="body2" color='grey'>{cuota.CuotaType}</Typography>
                    <Typography variant="body2" color='grey'>{formatCurrency(cuota.Price)}</Typography>
                    <Typography variant="body2" color='gray'>({formatDate(cuota.PaymentDate)})</Typography>
                </Stack>
                <Stack direction='row' gap='1rem' alignItems='center'>
                    <Typography variant="h6" color='green'>PAGADO</Typography>
                    <Stack direction='row' alignItems='center'>
                        <Tooltip title='Imprimir'><IconButton onClick={() => onPrint(cuota)}><Print fontSize="small" /></IconButton></Tooltip>
                        {email && <Tooltip title='Enviar mail'><IconButton onClick={() => onSendEmail(cuota)}><Send fontSize="small" /></IconButton></Tooltip>}
                        <Tooltip title='Editar'><IconButton onClick={() => onEdit(cuota)}><Edit fontSize="small" /></IconButton></Tooltip>
                        <Tooltip title='Eliminar'><IconButton onClick={() => onRemove(cuota)}><Close htmlColor='red' fontSize="small" /></IconButton></Tooltip>
                    </Stack>
                </Stack>
            </Stack>
            <Divider />
        </Box>
    )
}