import { Add, ArrowDownward, Church, Edit, Flip, MoreVert } from "@mui/icons-material"
import { Backdrop, Box, Button, Container, Divider, IconButton, Paper, Popover, Stack, Tooltip, Typography } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { AcceptCancelDialog } from "../Components/AcceptCancelDialog"
import { AddCardIdDialog } from "../Components/AddCardIdDialog"
import CenterCircularLoading from "../Components/CenterCircularLoading"
import { PayCuota } from "../Components/PayCuota"
import { CustomIconButton } from "../Components/CustomIconButton"
import EditHermano from "../Components/EditHermano"
import { useCuotasByNID, usePrintCuota } from "../Data/Cuotas/custom-hooks"
import { useCuotaTypes } from "../Data/CuotaTypes/custom-hooks"
import { useHermanoByNID } from "../Data/Hermanos/custom-hooks"
import { secondaryColor } from "../theme"
import { formatDate, possibleYears } from "../utils"
import { CuotaLine } from "../Components/CuotaLine"
import { EditCuota } from "../Components/EditCuota"
import { useEmails } from "../Data/Emails/custom-hooks"

const HermanoPage = () => {
    const { nid } = useParams()
    const cuotaTypes = useCuotaTypes()
    const hermano = useHermanoByNID(nid)
    const cuotas = useCuotasByNID(nid)
    const email = useEmails()
    const [anchorEl, setAnchorEl] = useState(null)
    const [openCreate, setOpenCreate] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openBajaDialog, setOpenBajaDialog] = useState(false)
    const [openDeathDialog, setOpenDeathDialog] = useState(false)
    const [openCardId, setOpenCardId] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedCuota, setSelectedCuota] = useState(null)
    const [openEditCuota, setOpenEditCuota] = useState(false)
    const [openRemoveCuota, setOpenRemoveCuota] = useState(false)

    const printCuota = usePrintCuota()

    const handleOpenEdit = () => {
        setOpenEdit(true)
        setAnchorEl(null)
    }

    const handleOpenEditCuota = (selected) => {
        setSelectedCuota(selected)
        setOpenEditCuota(true)
    }

    const handleOpenRemoveCuota = (selected) => {
        setSelectedCuota(selected)
        setOpenRemoveCuota(true)
    }

    const handleRemoveCuota = async () => {
        await cuotas.remove(selectedCuota.CuotaYear)
    }

    const closeRemoveCuota = () => {
        setOpenRemoveCuota(false)
        setSelectedCuota(null)
    }

    const handleOpenAddCardId = () => {
        setOpenCardId(true)
        setAnchorEl(null)
    }

    const handleBaja = async () => {
        await hermano.baja(true)
        setAnchorEl(null)
    }

    const closeBaja = () => {
        setOpenBajaDialog(false)
        setAnchorEl(null)
    }

    const handleDeath = async () => {
        await hermano.death()
        setAnchorEl(null)
    }

    const closeDeath = () => {
        setOpenDeathDialog(false)
        setAnchorEl(null)
    }

    const editHermano = async (newHermano) => {
        await hermano.edit(newHermano)
    }

    const deathOrBaja = () => {
        if (hermano.data.Death) {
            return <Typography color='grey' variant="h6">DIFUNTO</Typography>
        }
        if (hermano.data.Baja) {
            return <Typography color='red' variant="h6">BAJA</Typography>
        }
        return
    }

    const sendEmail = async (cuota) => {
        setLoading(true)
        await email.send(nid, cuota.CuotaYear)
        setLoading(false)
    }

    const getAllCuotaLines = () => {
        return cuotas.data.map((cuota, index) => <CuotaLine
            key={'cuota-' + index}

            email={hermano.data.Email}
            cuota={cuota}
            onPrint={(c) => printCuota.print(c)}
            onSendEmail={sendEmail}
            onEdit={(c) => handleOpenEditCuota(c)}
            onRemove={(c) => handleOpenRemoveCuota(c)}
        />)
    }

    if (hermano.loading || cuotas.loading || cuotaTypes.loading) {
        return <div><CenterCircularLoading /></div>
    }

    if (!hermano.data) {
        return <div>Lo sentimos pero no se ha encontrado al Hermano con NID: {nid}</div>
    }

    return (
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CenterCircularLoading />
            </Backdrop>
            <Stack direction='row' gap='3rem'>
                <Box flex={1}>
                    <Paper>
                        <Stack direction='column' p='.75rem' bgcolor={secondaryColor}>
                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                <Stack direction='row' alignItems='end'>
                                    <Typography variant="h6">{hermano.data.Name} {hermano.data.LastName}</Typography>
                                    <Typography variant="subtitle1" color='grey' style={{ marginLeft: '.5rem' }}>#{nid}</Typography>
                                </Stack>
                                <Tooltip title='Más'><IconButton onClick={(event) => setAnchorEl(event.currentTarget)}><MoreVert /></IconButton></Tooltip>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Box p='1rem'>
                            {hermano.data.CardId && <Typography variant="body1">Carnet: {hermano.data.CardId}</Typography>}
                            <Typography variant="body1">F. Nacimiento: {formatDate(hermano.data.BirthDate)}</Typography>
                            <Typography variant="body1">Tipo de hermano: {hermano.data.Type}</Typography>
                            <Typography variant="body1">DNI: {hermano.data.DNI}</Typography>
                            <Typography variant="body1">Email: {hermano.data.Email}</Typography>
                            <Typography variant="body1">Teléfono: {hermano.data.Phone}</Typography>
                            <Typography variant="body1">Dirección: {hermano.data.Address} {hermano.data.Zip ? `(${hermano.data.Zip})` : ''}</Typography>
                            <Typography variant="body1">Ciudad: {hermano.data.City}</Typography>
                        </Box>
                    </Paper>
                </Box>
                <Box flex={2}>
                    <Paper>
                        <Stack direction='row' alignItems='end' justifyContent='space-between' p='1rem' bgcolor={secondaryColor}>
                            <Stack direction='row' gap='.5rem'>
                                <Typography variant="h6">Cuotas</Typography>
                                {deathOrBaja()}
                            </Stack>
                            {(hermano.data.UpToDate) ?
                                <Typography variant="h6" color='green'>AL CORRIENTE</Typography>
                                : <Typography variant="h6" color='red'>NO AL CORRIENTE</Typography>
                            }
                        </Stack>
                        <Divider />
                        {getAllCuotaLines()}
                    </Paper>
                    {possibleYears(hermano.data, cuotas.data).length !== 0 && <Stack direction='row' justifyContent='end' p='1rem 0'>
                        <Button startIcon={<Add />} onClick={() => setOpenCreate(true)}>Añadir Cuota</Button>
                    </Stack>}
                </Box>
            </Stack >
            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Stack direction='column' gap='.5rem' p='.5rem'>
                    <CustomIconButton label='Editar' icon={<Edit fontSize='small' />} onClick={handleOpenEdit} />
                    <CustomIconButton label={(hermano.data.CardId) ? 'Cambiar carnet' : 'Añadir carnet'} icon={<Flip fontSize="small" />} onClick={handleOpenAddCardId} />
                    {!hermano.data.Baja && <CustomIconButton label='Dar de Baja' icon={<ArrowDownward fontSize='small' htmlColor='red' />} onClick={() => setOpenBajaDialog(true)} color='red' />}
                    {!hermano.data.Death && <CustomIconButton label='Defunción' icon={<Church fontSize='small' />} onClick={() => setOpenDeathDialog(true)} />}
                </Stack>
            </Popover>
            <AcceptCancelDialog
                open={openBajaDialog}
                onClose={closeBaja}
                title='¿Estás seguro?'
                contentText='Si aceptas el hermano será dado de baja. ESTA ACCIÓN NO SE PUEDE DESHACER'
                onCancel={closeBaja}
                onAccept={handleBaja}
                cancelLabel='Cancelar'
                acceptLabel='Dar de Baja'
            />
            <AcceptCancelDialog
                open={openDeathDialog}
                onClose={closeDeath}
                title='¿Estás seguro?'
                contentText='Si aceptas el hermano será dado como difunto. ESTA ACCIÓN NO SE PUEDE DESHACER'
                onCancel={closeDeath}
                onAccept={handleDeath}
                cancelLabel='Cancelar'
                acceptLabel='Aceptar'
            />
            <AcceptCancelDialog
                open={openRemoveCuota}
                onClose={closeRemoveCuota}
                title='¿Estás seguro?'
                contentText={(selectedCuota) ? `Si aceptas la cuota del año ${selectedCuota.CuotaYear} será eliminada. ESTA ACCIÓN NO SE PUEDE DESHACER` : ``}
                onCancel={closeRemoveCuota}
                onAccept={handleRemoveCuota}
                cancelLabel='Cancelar'
                acceptLabel='Eliminar'
            />
            <EditHermano open={openEdit} closeHandler={() => setOpenEdit(false)} successHandler={editHermano} hermano={hermano.data} />
            <PayCuota open={openCreate} onClose={() => setOpenCreate(false)} NID={nid} />
            <AddCardIdDialog open={openCardId} onClose={() => setOpenCardId(false)} NID={nid} />
            <EditCuota open={openEditCuota} NID={nid} onClose={() => setOpenEditCuota(false)} cuota={selectedCuota} />
        </Container >
    )
}

export default HermanoPage