import { Button, Drawer, MenuItem, Select, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useCuotasByNID } from "../Data/Cuotas/custom-hooks"
import { useCuotaTypes } from "../Data/CuotaTypes/custom-hooks"
import { useHermanoByNID } from "../Data/Hermanos/custom-hooks"
import { AcceptCancelDialog } from "./AcceptCancelDialog"

export const EditCuota = ({ open, onClose, NID, cuota }) => {
    const [CuotaType, setCuotaType] = useState((cuota) ? cuota.CuotaType : '')
    const [openWarning, setOpenWarning] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const cuotaTypes = useCuotaTypes()
    const hermano = useHermanoByNID(NID)
    const cuotas = useCuotasByNID(NID)

    useEffect(() => {
        if (cuota) {
            console.log(cuota)
            setCuotaType(cuota.CuotaType)
        }
    }, [cuota])

    const handler = () => {
        setSubmitting(true)

        const age = hermano.getAgeOn(cuota.CuotaYear)

        if (CuotaType === cuotaTypes.last.Name && age < cuotaTypes.last.FromDate) {
            setOpenWarning(true)
        }
        else {
            editCuota()
        }

    }

    const editCuota = async () => {
        await cuotas.edit(cuota.CuotaYear, CuotaType)

        onClose()
        setSubmitting(false)
    }

    const handleClose = () => {
        if (submitting) return
        onClose()
    }

    const getPosibleTypes = () => {
        if (cuota) {
            const ageOn = hermano.getAgeOn(cuota.CuotaYear)
            if (ageOn <= cuotaTypes.first.ToDate) {
                return [cuotaTypes.first]
            }
            if (ageOn >= cuotaTypes.last.FromDate) {
                return [cuotaTypes.last]
            }

            return cuotaTypes.data.filter((type) => type.Name !== cuotaTypes.first.Name)
        }
        return []
    }

    return (
        <Drawer
            PaperProps={{ sx: { width: { xs: "100%", md: 500 }, padding: '1rem' } }}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <Typography variant="h6">Editar Cuota</Typography>
            <Stack direction='column' padding='2rem 0'>
                <Select
                    required
                    label='Tipo de Cuota'
                    variant='standard'
                    value={CuotaType}
                    onChange={(e) => setCuotaType(e.target.value)}
                    disabled={submitting}
                >
                    {getPosibleTypes().map((o) => {
                        return (
                            <MenuItem key={o.Name} value={o.Name}>{o.Name}</MenuItem>
                        )
                    })}
                </Select>
            </Stack>
            <Stack direction='row' gap='1rem' justifyContent='end'>
                <Button variant='outlined' disabled={submitting} onClick={handleClose}>Cancelar</Button>
                <Button variant='contained' disabled={submitting} onClick={handler}>Cambiar Cuota</Button>
            </Stack>
            <AcceptCancelDialog
                title='¿Estás seguro?'
                contentText={`Estas cambiando una cuota como ${cuotaTypes.last.Name} a un hermano que no tiene la edad.\n¿Es correcto?`}
                acceptLabel='Cambiar Cuota'
                open={openWarning}
                onAccept={editCuota}
                onCancel={() => setSubmitting(false)}
                onClose={() => setOpenWarning(false)}
                blocking
            />
        </Drawer>
    )
}