import { Home, Person, Settings } from "@mui/icons-material"
import { Box, Stack } from "@mui/material"
import { useSelector } from "react-redux"
import { secondaryColor } from "../theme"
import { SideIcon } from "./SideIcon"

const pages = [
    { icon: <Home />, label: 'Inicio', url: '/home' },
    { icon: <Person />, label: 'Hermanos', url: '/hermanos' }
]

const Sidebar = () => {
    const authReducer = useSelector(state => state.auth)

    if (!authReducer.isAuthenticated) {
        return
    }

    return (
        <Box flex={1} style={{ maxWidth: '5rem', backgroundColor: secondaryColor }}>
            <Stack direction='column' justifyContent='space-between' height='calc(100% - 1rem)'>
                <Stack direction='column' gap={4} p='2.5rem 1rem'>
                    {
                        pages.map((p) =>
                            <SideIcon key={p.url} url={p.url} label={p.label} icon={p.icon} />
                        )
                    }
                </Stack>
                <SideIcon url={'/settings'} label='Ajustes' icon={<Settings />} />

            </Stack>
        </Box>
    )
}

export default Sidebar