import { Box, IconButton, Stack, Typography } from "@mui/material"
import Navbar from "./Components/Navbar"
import Sidebar from "./Components/Sidebar"
import { MyRoutes } from "./routes"
import { useUpdater } from './Data/Updater/useUpdater'
import { Replay } from "@mui/icons-material"
import { CenterLoading } from "./Components/CenterLoading"
import { useHermanos } from "./Data/Hermanos/custom-hooks"

export const Loader = () => {
  const updater = useUpdater()

  // Load before to improve times
  useHermanos()

  if (updater.loading) {
    return <CenterLoading label='Cargando datos' />
  }

  return (
    <>
      <Navbar />
      <Stack direction="row" gap='1rem' style={{ height: 'calc(100vh - 4rem)' }}>
        <Sidebar />
        <Box marginTop='1rem' marginRight='1rem' width='100%'>
          <MyRoutes />
        </Box>
      </Stack>
      <div
        onClick={() => window.location.reload(false)}
        style={{
          display: (!updater.loading && !updater.isLatest) ? 'block' : 'none',
          cursor: 'pointer',
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
          width: '300px',
          height: '70px',
          boxShadow: '0px 0px 6px 2px rgba(0,0,0,0.5)',
          borderRadius: '10px',
          background: 'white'
        }}
      >
        <Stack direction='row' justifyContent='space-between' padding='1rem' alignItems='center'>
          <Typography>Nueva actualización v{updater.lastVersion}</Typography>
          <IconButton><Replay /></IconButton>
        </Stack>
      </div>
    </>
  )
}