import { Box, Button, Grid, MenuItem, Select, TextField, Typography, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";

/*
field: {
    type: 'text' | 'password',
    label: string,
}
*/

export function Form(props) {

    const [submitting, setSubmitting] = useState(false)

    const inputRef = useRef()

    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus()
        }, 300);
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (submitting) return
        setSubmitting(true)
        const allData = e.target
        let allValues = {}
        for (let field of props.fields) {
            allValues[field.name] = allData[field.name].value
        }
        await props.cb(allValues)
        setSubmitting(false)
    }

    return (
        <Box>
            {props.title && <Typography variant="h6">{props.title}</Typography>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    {props.fields.map((field, index) => {
                        let fieldProps = { ...field }
                        if (props.focusFirst && index === 0) {
                            fieldProps.inputRef = inputRef
                        }

                        return (
                            <Grid key={field.name} item xs={field.xs} sm={field.sm}>
                                {fieldProps.type === 'select' ?
                                    <Select
                                        disabled={submitting}
                                        {...fieldProps}
                                        style={{ ...fieldProps.style, paddingTop: '1rem' }}
                                        defaultValue={props.options && props.options.defaults && props.options.defaults[fieldProps.name] ? props.options.defaults[fieldProps.name] : props.options[fieldProps.name][0]}
                                    >
                                        {props.options[fieldProps.name].map((o) => {
                                            return (
                                                <MenuItem key={o} value={o}>{o}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    : <TextField disabled={submitting} {...fieldProps} />
                                }
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
                    <Stack direction='row' gap='1rem' justifyContent='center'>
                        {props.cancelLabel && <Button variant='outlined' disabled={submitting} onClick={() => props.closeHandler()}>{props.cancelLabel}</Button>}
                        <Button type='submit' variant='contained' fullWidth={!props.cancelLabel} disabled={submitting}>{(props.buttonLabel) ? props.buttonLabel : 'Submit'}</Button>
                    </Stack>
                </Grid>
            </form>
        </Box>
    )
}