import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { webTheme } from './theme';
import store from './Redux/store';
import { Provider } from 'react-redux';
import { PrintCuotaProvider } from './Utils/PrintCuota';
import { NotificationProvider, PositionY } from '@magicdidac/notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={webTheme}>
        <BrowserRouter>
          <CookiesProvider>
            <NotificationProvider positionY={PositionY.top}>
              <PrintCuotaProvider />
              <App />
            </NotificationProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
